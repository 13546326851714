<template>
  <div ref="turnstile" :data-size="size"></div>
</template>

<script>
export default {
  props: {
    size: {
      type: 'normal' | 'flexible' | 'compact',
      required: true,
      default: 'flexible',
    }
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit';
    script.onload = () => {
      if (window.turnstile) {
        window.turnstile.render(this.$refs.turnstile, {
          sitekey: '0x4AAAAAAA4z0tAWgOPFICif',
        });
      }
    };
    document.head.appendChild(script);
  },
  methods: {
    // 获取 Turnstile 的响应令牌
    getResponse() {
      return new Promise((resolve, reject) => {
        const response = window.turnstile.getResponse(this.$refs.turnstile);
        if (response) {
          resolve(response);
        } else {
          reject('Turnstile response not found');
        }
      });
    },
    reset() {
      window.turnstile.reset(this.$refs.turnstile);
    }
  }
};
</script>
