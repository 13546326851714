<template>
  <div class="contact-us">
    <div class="wrapper">
      <div class="bg"></div>
      <div class="contact-form">
        <div class="form-item">
          <span class="label">{{ $t('contactus.service') }}</span>
          <div class="form-control">
            <a-select v-model="form.version" style="width: 100%;">
              <a-select-option v-for="item in packages" :key="item.name" :value="item.name">
                {{ $t(`package.${item.name}`) }}
              </a-select-option>
            </a-select>
          </div>
          <transition name="fade-in">
            <p v-if="errorTips.version" class="error-tips">{{ errorTips.version }}</p>
          </transition>
        </div>
        <div class="form-item">
          <span class="label">{{ $t('contactus.name') }}</span>
          <div class="form-control">
            <input v-model="form.name" type="text">
          </div>
          <transition name="fade-in">
            <p v-if="errorTips.name" class="error-tips">{{ errorTips.name }}</p>
          </transition>
        </div>
        <div class="form-item">
          <span class="label">{{ $t('contactus.phone') }}</span>
          <div class="form-control">
            <input v-model="form.phone" type="text">
          </div>
          <transition name="fade-in">
            <p v-if="errorTips.phone" class="error-tips">{{ errorTips.phone }}</p>
          </transition>
        </div>
        <div class="form-item">
          <span class="label">{{ $t('contactus.company') }}</span>
          <div class="form-control">
            <input v-model="form.company" type="text">
          </div>
          <transition name="fade-in">
            <p v-if="errorTips.company" class="error-tips">{{ errorTips.company }}</p>
          </transition>
        </div>
        <div class="form-item">
          <span class="label">{{ $t('contactus.email') }}</span>
          <div class="form-control">
            <input v-model="form.email" type="text">
          </div>
          <transition name="fade-in">
            <p v-if="errorTips.email" class="error-tips">{{ errorTips.email }}</p>
          </transition>
        </div>
        <div class="form-item">
          <span class="label">{{ $t('contactus.wechat') }}</span>
          <div class="form-control">
            <input v-model="form.wechat" type="text">
          </div>
          <transition name="fade-in">
            <p v-if="errorTips.wechat" class="error-tips">{{ errorTips.wechat }}</p>
          </transition>
        </div>
        <div class="form-item">
          <span class="label">{{ $t('contactus.website') }}</span>
          <div class="form-control">
            <input v-model="form.website" type="text">
          </div>
          <transition name="fade-in">
            <p v-if="errorTips.website" class="error-tips">{{ errorTips.website }}</p>
          </transition>
        </div>
        <div class="form-item">
          <span class="label">{{ $t('contactus.message') }}</span>
          <div class="form-control">
            <textarea ref="textarea" v-model="form.message"></textarea>
          </div>
          <transition name="fade-in">
            <p v-if="errorTips.message" class="error-tips">{{ errorTips.message }}</p>
          </transition>
        </div>
        <div class="form-item">
          <Turnstile ref="turnstile" size="normal"/>
          <transition name="fade-in">
            <p v-if="errorTips.turnstile_response" class="error-tips">{{ errorTips.turnstile_response }}</p>
          </transition>
        </div>
        <div class="form-item">
          <button class="button" type="button" @click="submit">{{ $t('common.submit') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import schema from 'async-validator';
import {contactUs} from '../services/index';
import Turnstile from "../components/Turnstile.vue";

const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export default {
  name: 'contact-us',
  components: {
    Turnstile
  },
  data() {
    const descriptor = {
      version: [{
        type: 'string',
        required: true,
        message: this.$t('contactus.required'),
      }],
      name: [{
        type: 'string',
        required: true,
        message: this.$t('contactus.required'),
      }],
      phone: [{
        type: 'string',
        required: true,
        message: this.$t('contactus.required'),
      }],
      company: [{
        type: 'string',
        required: true,
        message: this.$t('contactus.required'),
      }],
      email: [{
        type: 'string',
        required: true,
        message: this.$t('contactus.required'),
      }, {
        pattern: emailReg,
        message: this.$t('user.enterLegalEmail'),
      }],
      website: [{
        type: 'string',
        required: true,
        message: this.$t('contactus.required'),
      }],
      message: [{
        type: 'string',
        required: true,
        message: this.$t('contactus.required'),
      }],
      turnstile_response: [{
        type: 'string',
        required: true,
        message: this.$t('user.confirmAliCode'),
      }],
    }
    return {
      form: {
        version: '',
        name: '',
        phone: '',
        company: '',
        email: '',
        website: '',
        message: '',
        turnstile_response: '',
      },
      validator: new schema(descriptor),
      errorTips: {
        version: '',
        name: '',
        company: '',
        email: '',
        website: '',
        message: '',
        turnstile_response: '',
      }
    }
  },
  computed: {
    ...mapGetters(['packages'])
  },
  methods: {
    async submit() {
      this.form.turnstile_response = await this.$refs.turnstile.getResponse();
      this.validator.validate(this.form)
          .then(async () => {
            contactUs(this.form)
                .then(res => {
                  if (res.code === 0) {
                    this.$success({
                      title: this.$t('contactus.success')
                    });
                  }
                })
                .catch(err => {
                  if (!err.response) return;
                  this.$error({
                    title: this.$t(`error['${err.response.data.errorKey}']`)
                  });
                  this.$refs.turnstile.reset();
                })
          })
          .catch(({errors, fields}) => {
            let errorTips = {}
            errors.forEach(error => {
              if (!errorTips[error.field]) {
                errorTips[error.field] = error.message;
              }
            })
            this.errorTips = errorTips;
          });
    },
  }
}
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';

.contact-us {
  width: @container;
  margin: 50px auto;

  .wrapper {
    margin: 0 auto;
    display: flex;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    background: url('../assets/images/contactus.jpeg') no-repeat center center / cover;
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.05);
  }

  .bg {
    width: 510px;
  }

  .contact-form {
    flex: 1;
    padding: 38px;
  }

  .form-item {
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    text-align: right;

    .label {
      width: 80px;
      line-height: 32px;
      float: left;

      &:after {
        content: '：'
      }
    }

    .form-control {
      margin-left: 80px;
    }
  }

  .error-tips {
    position: absolute;
    left: 80px;
    font-size: 12px;
    padding-top: 4px;
    color: @red-color;
  }

  input {
    border: 1px solid #d9d9d9;
    padding: 6px 10px;
    border-radius: 4px;
    width: 100%;

    &:hover, &:focus {
      border-color: @primary-color;
    }
  }

  textarea {
    min-height: 127px;
    width: 100%;
    flex: 1;
    resize: none;
    // border: 1px solid #ccc;
    padding: 10px 15px;
    line-height: 1.5;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: border @transition-time linear;

    &:hover, &:focus {
      border-color: @primary-color;
    }
  }
}

@height: 40px;
/deep/ .nc-container {
  height: @height;

  #nc_1_wrapper {
    width: 100%;
  }

  .nc_scale {
    .nc_bg {
      background-color: @primary-color;
    }

    height: @height;

    span {
      height: @height;
      line-height: @height;
    }

    .nc-lang-cnt {
      color: #444;
    }

    .btn_ok {
      color: @primary-color;
      border-color: @primary-color;
    }
  }
}
</style>
